import {Box, Button, chakra, Container, Flex, Link as ChakraLink, Text} from '@chakra-ui/react'
import React from 'react'
import {Helmet} from "react-helmet";

import {graphql, Link} from 'gatsby';
import {GatsbyImage} from "gatsby-plugin-image";

const LinkText: React.FC = ({children}) => {

    return <Text as={"span"} fontFamily="Monoton" fontSize={{base: "lg", lg: "3xl"}} letterSpacing="0.1em"
                 fontWeight="" color="gray.600" _hover={{color: "gray.900"}}>{children}</Text>
}

const About = ({data}: { data: any }) => {

    return (
        <Flex direction={{base: "column", lg: "row"}} w="full" overflow="hidden" h="100vh">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Jelena Šijak</title>
                <meta name="description" content="My photography portfolio"/>
                <link rel="canonical" href="http://jelenasijak.com"/>

                <link rel="preconnect" href="https://fonts.googleapis.com"/>
                <link rel="preconnect" href="https://fonts.gstatic.com"/>
                <link
                    href="https://fonts.googleapis.com/css2?family=Mrs+Saint+Delafield&family=Ubuntu:wght@300;400&family=Luckiest+Guy&family=Monoton&display=swap"
                    rel="stylesheet"/>
            </Helmet>
            <Flex backgroundColor="#f5e3e6"
                  backgroundImage="linear-gradient(315deg, #f5e3e6 0%, #d9e4f5 74%)"
                  direction="column" w="full" maxW={{base: "full", lg: "300px"}} alignItems="center"
                  justifyContent="space-between" pt={{base: 12, lg: 24}} pb={{base: 12, lg: 4}}>
                <Flex alignItems="flex-start" flexWrap="wrap" direction={{base: "row", lg: "column"}} gridGap={4}>
                    <Link to={"/about"}>
                        <ChakraLink>
                            <LinkText>About</LinkText>
                        </ChakraLink>
                    </Link>
                    <ChakraLink href={"https://www.instagram.com/jelena.sijak.photography/"} target={"_blank"}>
                        <LinkText>Instagram</LinkText>
                    </ChakraLink>
                    <Link to={"/"}>
                        <ChakraLink>
                            <LinkText>Projects</LinkText>
                        </ChakraLink>
                    </Link>
                    <ChakraLink href={"mailto:jekasijak@gmail.com"} target={"_blank"}>
                        <LinkText>Contact</LinkText>
                    </ChakraLink>
                </Flex>
                <Box textAlign="center" display={{base: "none", lg: "block"}}>
                    <Text fontFamily="Ubuntu" fontWeight={300} color="gray.600">Photographed with ❤️ in Serbia.</Text>
                    <Text>
                        <chakra.span fontFamily="Mrs Saint Delafield" fontSize="4xl" color="gray.700">Jelena Šijak
                        </chakra.span>
                    </Text>
                </Box>
            </Flex>
            <Box w="full" overflow="scroll" backgroundColor="#fff">
                <Container maxW={"1220px"} mt={{base: 4, md: 12}} mb={12}>
                    <Flex direction={{base: "column", md: "row"}}>


                        <Flex direction={"column"} mr={{base: 0, md: 8}} mb={8} fontFamily="Ubuntu" color={"gray.800"} css={{
                            'p': {
                                marginBottom: 16
                            }
                        }}>
                            <Text>
                                Pozdrav!
                            </Text>
                            <Text>
                                Ja sam Jelena i mogu da vam pomognem u kreiranju produkt fotografija koje će prikazati
                                lepotu vašeg brenda i svojom autentičnošću istaći vas u gomili. Moj stil je vedar, veseo
                                i
                                šaren, a može biti i pastelan, senzualan i nežan. Imam puno iskustva u marketingu i
                                prisustvu na socijalnim mrežama što mi pomaže da bolje i dublje razumem potrebe za
                                adekvatnim predstavljanjem.
                                Živim i radim u Srbiji ali možemo sarađivati bez obzira na lokaciju!
                            </Text>
                            <Text fontWeight="bold">
                                Zbog čega je neophodno imati profesionalne produkt fotografije ukoliko želite da vaš
                                biznis raste?
                            </Text>
                            <Text>
                                Živimo u eri vizuelne estetike. Privlačne fotografije su danas imperativ za prezentaciju
                                i građenje odnosa sa ciljnom grupom vašeg brenda. Unikatna vizuelna prezentacija na
                                mrežama je danas esencija uspeha. Dobra fotografija zaustavlja skrolovanje feeda.
                                Vrhunska fotografija prodaje.
                            </Text>
                            <Text fontWeight="bold">
                                Kako saradnja izgleda?
                            </Text>
                            <Text fontWeight="semibold">
                                Korak 1:
                            </Text>
                            <Text>
                                Kontaktirajte me preko kontakt forme ili putem socijalnih mreža. Nije neophodno da u
                                potpunosti znate baš svaki detalj onoga što želite ali potrebno je da imate makar neki
                                okvir želja i očekivanja. Npr koliko fotografija vam je potrebno, kojih proizvoda, u kom
                                roku? Koliki je vaš budžet? Produkt fotografija nema fiksnu cenu. Ona zavisi od
                                kompleksnosti izvedbe, vremena i novca koje je potrebno uložiti u nju. Ukoliko imate
                                ogranicen budzet mozete ga navesti pa ću vam objasniti šta je moguće uklopiti u njega.
                            </Text>
                            <Text fontWeight="semibold">
                                Korak 2:
                            </Text>
                            <Text>
                                Nakon inicijalnog dogovora radim dodatno istraživanje vašeg brenda, boja koje koristite,
                                efekta koji želite da dobijete i priče koju vas brend priča. Uvek mi možete poslati
                                primere nekih fotografija ili brendova koje vam se dopadaju i kojima želite da težite
                                ukoliko isti postoje naravno. Možemo napraviti mood board na Pinterestu i zajedno ga
                                prokomentarisati. Uvek je dobro dati fotografu makar malu dozu kreativne slobode ali
                                ukoliko jasno i precizno znate šta vam je potrebno to treba navesti.
                            </Text>
                            <Text>
                                Inicijalna komunikacija je jako bitna jer se tu ugovaraju očekivanja i željeni
                                rezultati.
                            </Text>
                            <Text fontWeight="semibold">
                                Korak 3:
                            </Text>
                            <Text>
                                Ovde počinje magija i moj deo posla. Potrebno je samo da mi pošaljete proizvode, a
                                ostalo je na meni! Odabrane fotografije se obradjuju prvo u Lightroomu, gde se sređuju
                                pretezno boje, a zatim i u Photoshopu, gde se sređuju svi nedostaci i mane i fotografija
                                dovodi do savršenstva. Nakon obrade vi dobijate finalne verzije koje možete neograničeno
                                koristiti na svim platformama i za bilo koje namene (oglasi, postovi, website itd).
                            </Text>
                            <Text>
                                Ostalo je još samo da počnete da ih koristite i da uživate u novim lepim stvarima koje
                                vam dolaze!
                            </Text>
                            <Flex align={"center"}>
                                <Text>
                                    Povežimo se!
                                </Text>
                            </Flex>
                            <Box>
                                <Button as={"a"} href={"mailto:jekasijak@gmail.com"} color="gray.700"
                                        bg={"linear-gradient(315deg, #f5e3e6 0%, #d9e4f5 74%)"} _hover={{
                                    bg: "linear-gradient(95deg, #f5e3e6 0%, #d9e4f5 74%)"
                                }}
                                >Kontakt</Button>

                            </Box>
                        </Flex>
                        <Box minW={{base: "full", sm: "300px", lg: "40%"}}>
                            <GatsbyImage
                                alt="Jelena Šijak"
                                image={data.jeka.childImageSharp.gatsbyImageData}
                                loading="eager"
                                backgroundColor="#fff"
                                draggable={false}/>
                        </Box>
                    </Flex>
                </Container>


            </Box>
        </Flex>
    )
}

export default About

export const query = graphql`{
    jeka: file(name: {eq: "jeka"}) {
        name
        childImageSharp {
            gatsbyImageData(width:1200, placeholder: TRACED_SVG, layout: CONSTRAINED)
        }
    }
}
`
